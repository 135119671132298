import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//npm install @mui/material @emotion/react @emotion/styled
//npm install @mui/icons-material
import FormPhoneCallBack from '../components/FormPhoneCallBack'
import Card from 'react-bootstrap/Card';
import MUIIconButton from '../components/MUIIconButton';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from 'react-bootstrap/Button';

export default function Home() {
  const [errInfo, setErrInfo] = useState(false);

  const handleCloseErrInfo = (e, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setErrInfo(false);
  };

  const actionErrInfo = (
      <React.Fragment>
        <Button color="secondary" size="small" onClick={handleCloseErrInfo} />
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleCloseErrInfo}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );  

  return (
    <>
      <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={errInfo}
          // autoHideDuration={3000}
          onClose={handleCloseErrInfo}
          // message="Note archived"
          action={actionErrInfo}
          key={"topright"}
      >
        <Alert onClose={handleCloseErrInfo} severity="error" sx={{ width: '100%' }}>
          К сожалению в данный момент не удалось заказать обратный звонок.
        </Alert>
      </Snackbar>      
      <Row className="mb-4">
        <Col md={1} />
        <Col md={10}>
          <Alert severity="success">
            <h5>
              <strong>
                Юридическая консультация по всем юридическим вопросам, в любое удобное для Вас 
                время, в том числе в выходные дни и c выездом специалиста на дом 
                (по предварительной договоренности).
              </strong>
            </h5>
          </Alert>
        </Col>
        <Col md={1} />
      </Row>
      <Row>
        <Col md={2} />
        <Col md={8}>
          <Card style={{"opacity": "0.7"}}>
            <Card.Header><strong>ЮРИДИЧЕСКАЯ КОНСУЛЬТАЦИЯ БЕСПЛАТНО</strong></Card.Header>
            <Card.Body>
              <FormPhoneCallBack setErrInfo={setErrInfo} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={2} />
      </Row>      
      <MUIIconButton type="down" href="./about" />      
    </>
    );
}

// export class Home extends Component {
//   static displayName = Home.name;

//   render() {
//     return (
//       <div>
//         <h1>Hello, world!</h1>
//         <p>Welcome to your new single-page application, built with:</p>
//         <ul>
//           <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
//           <li><a href='https://facebook.github.io/react/'>React</a> for client-side code</li>
//           <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
//         </ul>
//         <p>To help you get started, we have also set up:</p>
//         <ul>
//           <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
//           <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
//           <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>
//         </ul>
//         <p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>
//       </div>
//     );
//   }
// }
