//import React, { useEffect } from 'react'; 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import MUIIconButton from '../components/MUIIconButton';
import HelpButton from '../components/HelpButton';
import { BsHouseDoor } from "react-icons/bs";
import { BsClockHistory } from "react-icons/bs";
import { BsTelephone } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import ExecJS from '../components/ExecJS';
import '../css/Contacts.css'

export default function Contacts(props) {

  return (
    <>
      <MUIIconButton type="up" href="./about" />
      <Row className="mb-4">
        <Col md={1} />
        <Col md={10}>
          <Card className='mb-5'>
            <Card.Header>
              <strong>Схема проезда {props.location === "taganrog" ? "г.Таганрог" : ""}</strong>
            </Card.Header>
            <Card.Body style={{ padding: "0px" }}>
              {
                props.location === "taganrog" ?
                <GetMap
                  location={props.location}
                  url="https://api-maps.yandex.ru/services/constructor/1.0/js/?sid=9oT8K8964pWQqoZLKKybp505UKwP7_T7&amp;height=260&amp;lang=ru_RU&amp;sourceType=constructor&amp;scroll=true"
                /> :
                ""
              }
            </Card.Body>
          </Card>        
        </Col>
        <Col md={1} />
      </Row>
      <HelpButton show={false} />
    </>
    );
}

function GetMap(props) {

  return (
    <>
      <Col id="map_0" md={12} className="panel-body" 
        style={{textAlign: "center", "padding": "0px", "height": "260px" }}>
        <ExecJS containerId="map_0" type="external" url={props.url}/>
      </Col>
      <Col md={12} style={{padding: "10px", border: "1px solid #eee"}}>
        <address className="addr">
          {
            props.location === "taganrog" ?
            <GetAddrTaganrog /> :
            ""
          }
        </address>        
      </Col>
    </>    
  );
}

function GetAddrTaganrog() {

  return (
    <>
      <Row>
        <Col md={4}>
          <ul>
            <li>
              <BsHouseDoor style={{color: "#000"}} />
              <strong> адрес: </strong>г.Таганрог, 
            </li>
            <li className="pd-l">
              <span>ул. Октябрьская, 15</span>
            </li>
          </ul>
        </Col>
        <Col md={5}>
          <ul>
            <li>
              <BsClockHistory style={{color: "#000"}} />
              <strong> время работы: </strong>          
            </li>
            <li className="pd-l">
              <span><strong>Пн.-Пт.:</strong> с 9-00 до 17-00 (без перерыва)</span>          
            </li>
            <li className="pd-l">
              <span><strong>Сб.-Вс.:</strong> выходной</span>          
            </li>
            <li className="pd-l" style={{ fontSize: "12px" }}>
              <span><strong>или в любое удобное для вас время</strong></span>          
            </li>
            <li className="pd-l" style={{ fontSize: "12px" }}>
              <span><strong>по предварительной договоренности</strong></span>
            </li>
          </ul>
        </Col>
        <Col md={3}>
          <ul>
            <li>
              <BsTelephone style={{color: "#000"}} />
              <strong> Телефоны: </strong>          
            </li>
            <li className="pd-l">
              +7(905)426-91-50
            </li>
            <li>
              <BsEnvelope style={{color: "#000"}} />
              <strong> e-mail: </strong>ksa.2007@bk.ru        
            </li>        
          </ul>
        </Col>
      </Row>
    </>
  );
}