import * as React from 'react';
import PropTypes from 'prop-types';
//npm install react-imask
import { IMaskInput } from 'react-imask';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const TextMaskPhoneNumber = React.forwardRef(function TextMaskPhoneNumber(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+7 (###) ###-##-##"
      definitions={{
        '#': /[0-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskPhoneNumber.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function PhoneEdit(props) {
  const [valuePhoneNumber, setValuePhoneNumber] = React.useState({
    phoneNumber: props.valuePhone.value,
    error: props.valuePhone.value.length !== 18
  });

  const handleChange = (event) => {
    setValuePhoneNumber({      
      [event.target.name]: event.target.value,
      error: event.target.value.length !== 18
    });
    props.setValuePhone({ value: event.target.value, 
      checkValidity: event.target.value.length === 18});
  };

  return (
    <Box>    
      <TextField
        required={true}
        error={valuePhoneNumber.error}
        size="small"
        type="tel"
        fullWidth={true}
        placeholder="+7 (___) ___-__-__"
        label="Номер телефона"
        value={valuePhoneNumber.phoneNumber}
        onChange={handleChange}
        name="phoneNumber"
        id="text-mask-phone-edit"
        InputProps={{
          inputComponent: TextMaskPhoneNumber,
        }}
        variant="outlined"
        helperText="Необходимо указать номер телефона, по которому мы сможем перезвонить."
      />
    </Box>
  );
}
