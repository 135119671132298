import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import IconButton from '@mui/material/IconButton';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Nav from 'react-bootstrap/Nav';
import '../css/MUIIconButton.css'

export default function MUIIconButton(props) {

  return (
    <>
    <Row md={12} className="mb-3 mt-2 justify-content-md-center">
        <Col md={5} />
        <Col className="icon-button-expand" md={2}>
          <Nav.Link href={props.href} style={{backgroundColor: "transparent"}}>
            <IconButton aria-label="more" size="large" className='icon-button-expand-animate'
            style={{color: "#fff"}}>
              {
                props.type && props.type === "down" ?
                <ExpandMore fontSize="inherit" /> :
                props.type && props.type === "up" ?
                <ExpandLess fontSize="inherit" /> :
                <ExpandMore fontSize="inherit" />
              }
            </IconButton>          
          </Nav.Link>
        </Col>
        <Col md={5} />
    </Row>
    {props.type && props.type === "down" ?
    <>
      <Row className="mb-5">&nbsp;</Row>
      <Row className="mb-3">&nbsp;</Row>
    </> :
    ""}
    </>
  );
}