import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function NameEditor(props) {
  const [valueName, setValueName] = React.useState({
    name: props.valueName.value,
    error: props.valueName.value.length === 0
  });

  const handleChange = (event) => {
    setValueName({      
      [event.target.name]: event.target.value,
      error: event.target.value.length === 0
    });
    props.setValueName({ value: event.target.value, 
      checkValidity: event.target.value.length !== 0});
  };

  return (
    <Box>    
      <TextField
        required={true}
        error={valueName.error}
        size="small"
        type="text"
        fullWidth={true}
        placeholder=""
        label="Ваше имя"
        value={valueName.name}
        onChange={handleChange}
        name="name"
        id="name-edit"
        variant="outlined"
        helperText="Необходимо указать как мы сможем к Вам обращаться."
      />
    </Box>
  );
}
