import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import { BsWhatsapp } from "react-icons/bs";
import Alert from '@mui/material/Alert';
import PhoneEdit from './PhoneEdit'
import NameEditor from './NameEditor'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function PhoneCallBack(props) {
  const [validated, setValidated] = useState(false);
  // form fields
  const [valueName, setValueName] = useState({value: "", checkValidity: false});
  const [valuePhone, setValuePhone] = useState({value: "", checkValidity: false});
  const [valueApproval, setValueApproval] = useState(false);
  //
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // const handleSubmit = (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   }
  //
  //   setValidated(true);
  // };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    //
    event.preventDefault();
    event.stopPropagation();
    //
    setValidated(true);
    //
    if (valueName.checkValidity && valuePhone.checkValidity && form.checkValidity()) {
      // put data
      dataSubmit(
        { name: valueName.value, 
          phone: valuePhone.value, 
          approval: valueApproval 
        }, props.setErrInfo ? props.setErrInfo : setOpenSnackbar);
    }
  };
  
  const handleCloseSnakbar = (e, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenSnackbar(false);
  };

  const actionSnackbar = (
      <React.Fragment>
        <Button color="secondary" size="small" onClick={handleCloseSnakbar} />
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleCloseSnakbar}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

  return (
    <>
      <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSnackbar}
          // autoHideDuration={3000}
          onClose={handleCloseSnakbar}
          // message="Note archived"
          action={actionSnackbar}
          key={"topright"}
      >
        <Alert onClose={handleCloseSnakbar} severity="error" sx={{ width: '100%' }}>
          К сожалению в данный момент не удалось заказать обратный звонок.
        </Alert>
      </Snackbar>
      <Row className="justify-content-md-center">
        <Col md="1" />
        <Col md="10">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Alert severity="info">
              Закажите обратный звонок и мы перезвоним Вам.
            </Alert>
            <Form.Group className="mt-3 mb-2" as={Col} controlId="name">
              {/* <Form.Label><strong>Ваше имя:</strong></Form.Label>
              <Form.Control
                required
                autoFocus
                type="text"              
              />
              <Form.Control.Feedback type="invalid">
                Необходимо указать как мы сможем к Вам обращаться.
              </Form.Control.Feedback> */}
              <NameEditor 
                valueName={valueName}
                setValueName={setValueName}
              />
            </Form.Group>
            <Form.Group className="mb-2" as={Col} controlId="phone">
              {/* <Form.Label><strong>Номер телефона:</strong></Form.Label>
              <Form.Control
                required
                type="tel"
                placeholder="+7 (___) ___-__-__"
              />
              <Form.Control.Feedback type="invalid">
                Необходимо указать номер телефона, по которому мы сможем перезвонить.
              </Form.Control.Feedback> */}
              <PhoneEdit 
                valuePhone={valuePhone}
                setValuePhone={setValuePhone}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Check              
                required
                label="Соглас(ен)на на обработку персональных данных."
                feedback="Необходимо согласиться на отправку нам вашего имени и номера телефона."
                feedbackType="invalid"
                onChange={event => setValueApproval(event.target.checked)}
              />
            </Form.Group>
            <Col md={{ span: 10, offset: 9 }}>
              <Button variant="outline-primary" type="submit">
                <BsWhatsapp style={{color: "green"}} />{' '}Отправить
              </Button>
            </Col>
          </Form>
        </Col>
        <Col md="1" />
      </Row>
    </>
  );
}

async function dataSubmit(data, setErrInfo) {
  const response = await fetch("phonecallback", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(data)
  });
  //
  if (response.statusText === "OK") {
    //debug
    //const responseResult = await response.json();
    const responseResult = await response.text();
    console.log(responseResult);
    //
    if (localStorage)
      localStorage.setItem('FPCB_SD', JSON.stringify(data));
    //
    //if (window.location.href.indexOf("about") == -1)
    window.location.href = './about';
  }
  else {
    setErrInfo(true)
  }
}
