import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
// import { BsXCircle } from "react-icons/bs";
import FormPhoneCallBack from './FormPhoneCallBack';
import "../css/HelpButton.css"

export default function PhoneCBackMDialog(props) {
  const [show, setShow] = useState(props.show);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
    return (
      <>  
        <div className="help-button" onClick={handleShow} />          

        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Перезвоним Вам</Modal.Title>
          </Modal.Header>
          <Modal.Body><FormPhoneCallBack /></Modal.Body>
          {/* <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              <BsXCircle style={{color: "red"}} />{' '}
              Закрыть
            </Button>
          </Modal.Footer> */}
        </Modal>
      </>
    );
}
