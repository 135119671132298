import { useEffect } from 'react'; 

export default function ExecJS(props) {
  useEffect(() => {
    // componentDidMount() {}
    const containerScript = document.getElementById(props.containerId);
    const execScript = document.createElement('script');
    //
    if (props.type === "external")
    {
      // load external script
      //const externalScript = document.createElement('script');
      execScript.src = props.url;
      execScript.async = true;
      //execScript.charSet="utf-8"
      //document.head.append(externalScript);
      containerScript.append(execScript);
    }
    else if (props.type === "inline") {
      // load inline script
      //const inlineScript = document.createElement('script');
      execScript.innerHTML = props.url;
      // document.body.append(inlineScript);
      containerScript.append(execScript);
    }

    // componentWillUnmount() {}
    return () => {
      execScript.remove();
    };
  }, [props]);  
}
