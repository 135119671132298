// import { Counter } from "./pages/Counter";
// import { FetchData } from "./pages/FetchData";
import Home from "./pages/Home";
import About from "./pages/About";
import Contacts from "./pages/Contacts";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/about',
    element: <About />
  },
  {
    path: '/contact-main',
    element: <Contacts location="taganrog" />
  }
];

export default AppRoutes;
