import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../imgs/logo.png';
//npm install react-icons --save
import { BsTelephoneFill } from "react-icons/bs";
import { BsEnvelopeFill } from "react-icons/bs";
import { BsHouseDoor } from "react-icons/bs";
import { BsStar } from "react-icons/bs";
import { BsCursor } from "react-icons/bs";
import '../css/NavMenu.css';

export default function NavMenu() {
  return (
    <Navbar key={false} 
        // bg="primary" 
        style={{backgroundColor: "rgba(124, 34, 10, 0.9)", color: "#ddd"}}
        variant="dark" 
        expand={false} 
        className="mb-3"           
        >
          <Container fluid>
            <Col md={12}>
              <Row>
                <Col md={1}>
                  <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} />            
                </Col>
                <Col md={4}>
                  <Navbar.Brand href="/">
                      <img
                        src={logo}
                        height='38'
                        className='d-inline-block'
                        alt='Logo'
                      />
                  </Navbar.Brand>
                </Col>
                <Col md={3}>
                  <Phone />
                </Col>
                <Col md={3}>
                  <Email />
                </Col>
                <Col md={1} />
              </Row>
            </Col>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${false}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
              placement="start"
              style={{backgroundColor: "rgba(124, 34, 10, 0.9)", color: "#ddd"}}
            >
              <Offcanvas.Header closeButton
              style={{borderStyle: "solid", borderWidth: "1px", borderColor: "#ccc", 
              backgroundColor: "rgba(124, 34, 10, 0.9)", color: "#ddd"}}
              >
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`}>
                  <img
                    src={logo}
                    height='40'
                    className='d-inline-block'
                    alt='Logo'
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">                  
                  <Nav.Link href="/">
                    &nbsp;&nbsp;<BsHouseDoor style={{color: "#fff"}} />
                    &nbsp;&nbsp;&nbsp;Главная
                  </Nav.Link>
                  <Nav.Link href="/about">
                    &nbsp;&nbsp;<BsStar style={{color: "#fff"}} />
                    &nbsp;&nbsp;&nbsp;О компании
                  </Nav.Link>
                  <Nav.Link href="/contact-main">
                    &nbsp;&nbsp;<BsCursor style={{color: "#fff"}} />
                    &nbsp;&nbsp;&nbsp;Контакты г.Таганрог
                  </Nav.Link>
                </Nav>         
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
  );
}

function Phone() {

  return (
    <a href="tel:+7 905 426 91 50" rel="nofollow" 
      style={{textDecoration: "none", "color": "#ddd",
        verticalAlign: "middle", fontSize: "16px", 
        fontWeight: "bold"}}>
      <span><BsTelephoneFill /> +7(905)426-91-50</span>
    </a>
  );
}

function Email() {

  return (
    <a href="mailto:ksa.2007@bk.ru" 
      style={{textDecoration: "none", "color": "#ddd",
        verticalAlign: "middle", fontSize: "16px", 
        fontWeight: "bold"}}>
      <span><BsEnvelopeFill /> ksa.2007@bk.ru</span>
    </a>
  );
}

// import React, { Component } from 'react';
// import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
// import { Link } from 'react-router-dom';
// import '../css/NavMenu.css';

// export class NavMenu extends Component {
//   static displayName = NavMenu.name;

//   constructor (props) {
//     super(props);

//     this.toggleNavbar = this.toggleNavbar.bind(this);
//     this.state = {
//       collapsed: true
//     };
//   }

//   toggleNavbar () {
//     this.setState({
//       collapsed: !this.state.collapsed
//     });
//   }

//   render() {
//     return (
//       <header>
//         <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
//           <NavbarBrand tag={Link} to="/">Vash_jurist</NavbarBrand>
//           <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
//           <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
//             <ul className="navbar-nav flex-grow">
//               <NavItem>
//                 <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
//               </NavItem>
//               <NavItem>
//                 <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
//               </NavItem>
//               <NavItem>
//                 <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
//               </NavItem>
//             </ul>
//           </Collapse>
//         </Navbar>
//       </header>
//     );
//   }
// }
