import ExecJS from '../components/ExecJS';
import '../css/Footer.css';

export default function Footer() {

  return (
    <footer>
        {/* <hr />
        <p>
            &copy; ООО КорпТех, 2022<br />
            Версия: 1.0.0.1
        </p> */}
        <div className="navbar-footer navbar-fixed-bottom text-center">
          <div className="row">
            <div className="col-md-4 hidden-xs hidden-sm counter text-pt">
                <a target="_blank" rel="noreferrer" href="http://www.1gb.ru?cnt=96324">
                  <img runat="server" src="http://counter.1gb.ru/cnt.aspx?u=96324" 
                      border="0" width="88" height="31" alt="1Gb.ru counter" hspace="1" 
                      title="1Gb.ru: статистика посещений (всего, за сегодня)" />
                </a>
                {/* Yandex.Metrika informer */}
                <a href="https://metrika.yandex.ru/stat/?id=40912054&amp;from=informer" 
                  target="_blank" rel="noreferrer">
                  <img src="https://informer.yandex.ru/informer/40912054/3_1_FFFFFFFF_EFEFEFFF_0_pageviews"
                      style={{"width":"88px", "height":"31px", "border":"0"}} alt="Яндекс.Метрика" 
                      title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)" 
                      className="ym-advanced-informer" data-cid="40912054" data-lang="ru" />
                </a>
                {/* Yandex.Metrika informer */}
            </div>
            <div id="yastatic-share" className="col-md-4 social text-pt">            
              {/* <script src="//yastatic.net/es5-shims/0.0.2/es5-shims.min.js"></script>
              <script src="//yastatic.net/share2/share.js"></script>  */}
              <ExecJS containerId="yastatic-share" type="external" 
                url="//yastatic.net/es5-shims/0.0.2/es5-shims.min.js" />
              <ExecJS containerId="yastatic-share" type="external" 
                  url="//yastatic.net/share2/share.js" />
              <div className="ya-share2" data-services="vkontakte,facebook,odnoklassniki,moimir,viber,whatsapp"></div>
            </div>
            <div className="col-md-4 hidden-xs hidden-sm footer-logo text-pt">
              <p>&copy; 2016,2022 <span> ООО "РуСофтТех"</span></p>
            </div>
          </div>
        </div>
    </footer>
  );
}
