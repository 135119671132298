import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import MUIIconButton from '../components/MUIIconButton';
import HelpButton from '../components/HelpButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ImgFotoEmployee from '../imgs/fotoEmployee.jpg';
import '../css/About.css'

export default function About() {
  const [openSnackbar, setOpenSnackbar] = useState(FPCBSD());
  
  const handleCloseSnakbar = (e, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenSnackbar({ show: false, data: {} });
  };

  const actionSnackbar = (
      <React.Fragment>
        <Button color="secondary" size="small" onClick={handleCloseSnakbar} />
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleCloseSnakbar}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

  return (
    <>
      <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSnackbar.show}
          // autoHideDuration={3000}
          onClose={handleCloseSnakbar}
          // message="Note archived"
          action={actionSnackbar}
          key={"topright"}
      >
        <Alert onClose={handleCloseSnakbar} severity="info" sx={{ width: '100%' }}>
            <GetFPCBSDData FPCBSD={openSnackbar} />
        </Alert>
      </Snackbar>
      <MUIIconButton type="up" href="./" />
      <Row className="mb-4">
        <Col md={1} />
        <Col md={10}>
          <Card>
            <Card.Header><strong>Всегда рады Вам помочь</strong></Card.Header>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Card  style={{height: "730px"}}>
                    <Card.Img variant="top" src={ImgFotoEmployee} />
                    <Card.Body>
                      {/* <Card.Title>Card Title</Card.Title> */}
                      <Card.Text>
                        <strong>
                          Наша компания оказывает бесплатную юридическую консультацию. 
                          Огромный опыт работы сотрудников в сфере юриспруденции, 
                          позволяет Вам получить надежную, квалифицированную 
                          юридическую помощь.
                        </strong>
                      </Card.Text>
                      <Card.Text>
                        <strong>
                          Сотрудники имеют высшее юридическое образование и 
                          работают в различных направлениях юридической деятельности
                          для защиты законных прав и интересов наших клиентов.
                        </strong>
                      </Card.Text>
                      {/* <Button variant="primary">Go somewhere</Button> */}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6}>
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        Некоторые направления юридической деятельности нашей компании.
                      </Card.Title>
                        <ul className="no-marker">
                          <li>Подготовка исковых заявлений.</li>
                          <li>Представление и защита в суде.</li>
                          <li>Сопровождение исполнительного производства.</li>
                          <li>Финансовое право. Взыскание/списание (реструктуризация и оптимизация) долгов.</li>
                          <li>Законное избавление от долгов, банкротство.</li>
                          <li>Антиколлекторские услуги.</li>
                          <li>Семейное право. Развод, алименты, установление отцовства, лишение родительских прав, раздел имущества...</li>
                          <li>Трудовое право. Оспаривание увольнения, возврат невыплаченной заработной платы...</li>
                          <li>Наследство. Наследование неоформленного/незарегистрированного имущества, оспаривание дарственной, завещания...</li>
                          <li>Защита прав потребителей. Оспаривание и возврат денежных средств...</li>
                          <li>Жилищные вопросы. Споры с соседями, взыскание ущерба, приватизация, вопросы ЖКХ...</li>
                          <li>Составление или выдача заключения по гражданско-правовому договору и его сопровождение.</li>                          
                        </ul>                        
                    </Card.Body>
                  </Card>
                </Col>
              </Row>              
            </Card.Body>
          </Card>        
        </Col>
        <Col md={1} />
      </Row>
      <MUIIconButton type="down" href="./contact-main" />
      <HelpButton show={false} />
    </>
    );
}

function FPCBSD() {
  let result = { show: false, data: {} }
  if (localStorage) {
    let data = localStorage.getItem('FPCB_SD');
    if (data) {    
      result = { show: true, data: JSON.parse(data) };
      localStorage.removeItem('FPCB_SD');
    }
  }
  return result;
}

function GetFPCBSDData(props) {
  let result = "";
  if (props.FPCBSD.show) {
    result = props.FPCBSD.data.name + 
      ", если Вы верно указали тел.: " +
      props.FPCBSD.data.phone + 
      ", мы перезвоним Вам.";
  }
  return result;
}
